
import { debounce, FormControl, Grid, LinearProgress, MenuItem, Select, SelectChangeEvent, SelectProps, TextField, } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { FilingQueue } from "./PolicyDashboardElements/FilingQueue";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { NewCompletedFilings } from "./PolicyDashboardElements/NewCompletedFilings";
import { FMFilters, getCompletedFilings__Resp, ModelForFilters, NewCompletedFilingsModel } from "../models/NewCompletedFilingsModel";
import { OutstandingFilings } from "./PolicyDashboardElements/OutstandingFilings";
import Swal from "sweetalert2";
import moment from "moment";
import { writeXlsxFile } from "../helper/utils";
import { columnListDetailed, columnListShort, excelItemsAll, excelItemsDetailed, excelItemsShort } from "../components/Elements/ColumnList";
import { CompletedFilings } from "./PolicyDashboardElements/CompletedFilings";
import formatPrice from "../helper/currencyFormatter";
import { debug } from "console";
import { GridFilterItem, GridFilterModel, GridLinkOperator, GridSortModel } from "@mui/x-data-grid-pro";
import { isValid } from "../components/Elements/PolicyStatuses";
import { apiClient } from "../helper/api";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const initValue: getCompletedFilings__Resp = {
  getCompletedFilings_Respdata: [],
  totalCount: 0
}

export enum FMTabs {
  OutStandingFiling = 8,
  ReadyToFiling = 6,
  CompletedFiling = 5
}



function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography> */}
          {children}
          {/* </Typography> */}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {

  const [value, setValue] = React.useState(8);
  const { user } = useAuth();
  const token = user?.token;
  const [loading, setloading] = React.useState(false);
  const [newComponentDatawithCount, setNewComponentData] = useState<getCompletedFilings__Resp>(initValue)

  const [componentData, setcomponentData] = useState([]);
  const [stateId, setstateId] = useState(0);

  const [PolNumber, setpolNumber] = useState("");
  const [insuredName, setinsuredName] = useState("");

  const [effectiveDateEnd, seteffectiveDateEnd] = useState("");
  const [effectiveDateStart, seteffectiveDateStart] = useState("");
  const params = useParams();

  const PloicyNum = React.useRef("");
  const encodedPolicyNumber = encodeURIComponent(PloicyNum.current);
  const insName = React.useRef("");
  const effectiveDateEd = React.useRef("");
  const effectiveDateSt = React.useRef("");
  const stateData = React.useRef(0);
  const customerData = React.useRef(0);
  const [customerId, setCustomerId] = useState(0);
  const [getCustomerPolicyIds, setCustomerPolicyIds] = React.useState<number[]>([]);
  const [_currentPage, setCurrentPage] = React.useState(1);
  const [_pageSize, setPageSize] = React.useState(50);
  const [totalCount, setTotalCount] = React.useState(0);
  const sortModelRef = useRef<GridSortModel>([]);
  var firstDataCount = 0

  // const [selectedRows, setSelectedRows] = useState<NewCompletedFilingsModel[] | null>([]);
  const [selectedRows, setSelectedRows] = useState<NewCompletedFilingsModel[] | null>([]);

  const fetchData = (action: string, newValue: number) => {
    setloading(true);
    console.log(action);
    console.log("refreshdata.");

    const options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
    if (effectiveDateSt.current != null && effectiveDateSt.current != '' && effectiveDateSt.current != undefined) {
      let start: Date = new Date(effectiveDateSt.current);
      effectiveDateSt.current = start.toLocaleDateString('en-US', options);
    }

    if (effectiveDateEd.current != null && effectiveDateEd.current != '' && effectiveDateEd.current != undefined) {
      let end: Date = new Date(effectiveDateEd.current ?? '');
      effectiveDateEd.current = end.toLocaleDateString('en-US', options);

    }
    apiClient(`/PolicyDashBoard/${action}?userId=${user?.userId}
      &&policyNumber=${PloicyNum.current}&&stateId=${stateData.current}
      &&effectiveDateStart=${effectiveDateSt.current}
      &&effectiveDateEnd=${effectiveDateEd.current}
      &&insuredName=${insName.current}&&customerId=${customerData.current}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((responseJson) => {
        if (!responseJson) return
        // Do something with the response
        setloading(false);
        console.log(responseJson, "file");
        if (responseJson.data != null) {
          if (newValue === 6) {
            setNewComponentData(responseJson.data);
            console.log("policy data : " + responseJson.data);
          } else {
            setcomponentData(responseJson.data);
          }

        } else {
          //alert("No Record Found");
          setNewComponentData(initValue);
          setcomponentData([]);
          console.log("No Record Found");
        }
      })
      .finally(() => {
        setloading(false);
        setValue(newValue);
      })
    setValue(newValue);
  };


  const fetchNewData = (action: string, newValue: number, pageSize: number, pageNumber: number) => {
    setloading(true);


    apiClient(`/PolicyDashBoard/${action}?userId=${user?.userId}
      &&policyNumber=${PloicyNum.current}&&stateId=${stateData.current}
      &&effectiveDateStart=${effectiveDateSt.current}
      &&effectiveDateEnd=${effectiveDateEd.current}
      &&insuredName=${insName.current}
      &&customerId=${customerData.current}
      &&pageSize=${pageSize}&&pageNumber=${pageNumber}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((responseJson) => {
        setloading(false);


        if (responseJson.data) {
          const customerPolicyIds = responseJson?.data?.getCompletedFilings_Respdata?.map((m: any) => m.customerpolicyid) || [];
          console.log("mainData", responseJson.data);

          if (customerPolicyIds.length > 0) {
            setCustomerPolicyIds(customerPolicyIds);
          }

          if ([5, 6, 8].includes(newValue)) {
            if (responseJson.data.totalCount > 0) {
              setNewComponentData(responseJson.data);
              setTotalCount(responseJson.data.totalCount);

              // console.log("policy data: ", responseJson.data);
            } else {
              setNewComponentData(initValue);
              console.log("No Record Found");
            }
          } else {
            setcomponentData(responseJson.data);
          }
        } else {
          setNewComponentData(initValue);
          setcomponentData([]);
          // console.log("No Record Found");
        }
      })
      .finally(() => {
        setloading(false);
        setValue(newValue);
      })

    setValue(newValue);
  };
  interface dataParams {
    customerPolicyId: number
  }
  const initCustValue: dataParams = {
    customerPolicyId: 0
  }

  const getCustomerPolicyDetails = async (customerPolicyId: number): Promise<string> => {
    try {
      setloading(true);
      const initCustValue = {
        customerPolicyId: customerPolicyId
      };
      var action = 'getPolicySubStatusByCustPolId';
      const res = await apiClient(`/PolicyDashBoard/${action}`,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }),
          body: JSON.stringify(initCustValue)
        }
      );

      if (res?.data !== null) {
        setloading(false);
        return res.data;
      } else {
        setloading(false);
        throw new Error("Response data is null");
      }
    } catch (error) {
      setloading(false);
      throw error;
    }
  };

  const fetchDataSorting = (action: string, newValue: number, pageSize: number, pageNumber: number, field: string, sort?: string) => {
    setloading(true);
    console.log(action);
    console.log("refreshdata.");
    const options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
    if (effectiveDateSt.current != null && effectiveDateSt.current != '' && effectiveDateSt.current != undefined) {
      let start: Date = new Date(effectiveDateSt.current);
      effectiveDateSt.current = start.toLocaleDateString('en-US', options);
    }

    if (effectiveDateEd.current != null && effectiveDateEd.current != '' && effectiveDateEd.current != undefined) {
      let end: Date = new Date(effectiveDateEd.current ?? '');
      effectiveDateEd.current = end.toLocaleDateString('en-US', options);

    }
    apiClient(`/PolicyDashBoard/${action}?userId=${user?.userId}
      &&policyNumber=${PloicyNum.current}
      &&stateId=${stateData.current}
      &&effectiveDateStart=${effectiveDateSt.current}
      &&effectiveDateEnd=${effectiveDateEd.current}
      &&insuredName=${insName.current}
      &&customerId=${customerData.current}
      &&pageSize=${pageSize}
      &&pageNumber=${pageNumber}
      &&field=${field}
      &&sort=${sort}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((responseJson) => {
        if (!responseJson) return
        // Do something with the response
        setloading(false);
        console.log(responseJson, "file");
        if (responseJson.data != null) {
          const customerPolicyIds = responseJson.data.getCompletedFilings_Respdata.map((m: any) => m.customerpolicyid);
          if (customerPolicyIds.length > 0) {
            setCustomerPolicyIds(customerPolicyIds);
          }
          if (newValue === 6 || newValue === 8 || newValue === 5) {
            if (responseJson.data.totalCount > 0) {
              setNewComponentData(responseJson.data);
            }
            else {
              //alert("No Record Found");
              setNewComponentData(initValue);
              console.log("No Record Found");
            }
          } else {
            setcomponentData(responseJson.data);
          }
        } else {
          //alert("No Record Found");
          setNewComponentData(initValue);
          setcomponentData([]);
          console.log("No Record Found");
        }
      })
      .finally(() => {
        setloading(false);
        setValue(newValue);
      })
    setValue(newValue);
  };


  const initFilterModel: ModelForFilters = {
    customerpolicyid: 0
  }

  const [queryOptions, setQueryOptions] = React.useState<FMFilters>();

  const debouncedOnFilterChange = debounce((filterModel: GridFilterModel, tabValue: number) => {

    setloading(true);

    // Create new filters based on the current filter model 
    // const newFilters: FMFilters = { items: filterModel.items.map(item => ({
    //     columnField: item.columnField,
    //     value: item.value.trim() // Trim value to remove extra spaces
    //   }))
    // };
    

    const newFilters: FMFilters = {
      items: filterModel.items.map(item => {
        let value = item.value ? item.value.trim() : ""; // Trim value to remove extra spaces
      
        if (item.columnField === 'grossPremium') {
          value = value.replace('$', '').replace(',','').replace('.00',''); 
          
          console.log("premium",value);
        }
    
        return {
          columnField: item.columnField,
          value: value
        };
      })
    };

    // Update query options with the new filters
    setQueryOptions(newFilters);
    console.log("completedvalue", newFilters)

    // Check if there are any filters with non-empty values
    const hasValue = newFilters.items.some(item => item.value !== '');

    const getEndpointForTabValue = (tabValue: number) => {
      switch (tabValue) {
        case FMTabs.OutStandingFiling: return "getOutstandingFilingsFilterData";
        case FMTabs.ReadyToFiling: return "getCompletedFilingsFilterData";
        case FMTabs.CompletedFiling: return "getClosedFilingsFilterData";
        default: return null;
      }
    };

    const fetchData = (tabValue: number, filters: FMFilters) => {
      const endpoint = getEndpointForTabValue(tabValue);
      console.log("EndPoint", endpoint);
      if (endpoint) {
        fetchDataForFilter(endpoint, tabValue, _pageSize, _currentPage, filters);
      }
    };

    if (hasValue) {
      console.log("grosspremium", newFilters);

      fetchData(tabValue, newFilters);
    } else {
      setloading(true);
      // Clear filters if no values are present
      const clearedFilters = { items: [] };
      setQueryOptions(clearedFilters);
      //Fetch data without filters
      setPageSize(50);
      fetchData(tabValue, newFilters);

    }

    // setloading(false); // Stop loading after fetch call
  }, 1200); // 1 second debounce delay


  const fetchDataForFilter = (action: string, newValue: number, pageSize: number, pageNumber: number, newFilters?: FMFilters) => {
    setloading(true);
    const options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
    if (effectiveDateSt.current != null && effectiveDateSt.current != '' && effectiveDateSt.current != undefined) {
      let start: Date = new Date(effectiveDateSt.current);
      effectiveDateSt.current = start.toLocaleDateString('en-US', options);
    }

    if (effectiveDateEd.current != null && effectiveDateEd.current != '' && effectiveDateEd.current != undefined) {
      let end: Date = new Date(effectiveDateEd.current ?? '');
      effectiveDateEd.current = end.toLocaleDateString('en-US', options);

    }
    initFilterModel.customerId = customerData.current;
    initFilterModel.pageNumber = pageNumber;
    initFilterModel.pageSize = pageSize;
    initFilterModel.field = sortModelRef.current[0]?.field ?? '';
    initFilterModel.sort = sortModelRef.current[0]?.sort ?? '';

    initFilterModel.agency = newFilters?.items.find(m => m.columnField === "agency")?.value;
    initFilterModel.invoiceNumber = newFilters?.items.find(m => m.columnField === "invoiceNumber")?.value;
    initFilterModel.dueDate = newFilters?.items.find(m => m.columnField === "dueDate")?.value;
    initFilterModel.effectiveDate = newFilters?.items.find(m => m.columnField === "effectiveDate")?.value;
    initFilterModel.grossPremium = newFilters?.items.find(m => m.columnField === "grossPremium")?.value;
    initFilterModel.homeState = newFilters?.items.find(m => m.columnField === "homeState")?.value;
    initFilterModel.insuredName = newFilters?.items.find(m => m.columnField === "insuredName")?.value;
    initFilterModel.invoiceDate = newFilters?.items.find(m => m.columnField === "invoiceDate")?.value;
    initFilterModel.policyExpDate = newFilters?.items.find(m => m.columnField === "policyExpDate")?.value;
    initFilterModel.policyNumber = newFilters?.items.find(m => m.columnField === "policyNumber")?.value;
    initFilterModel.transactionTypeName = newFilters?.items.find(m => m.columnField === "transactionTypeName")?.value;
    initFilterModel.status = newFilters?.items.find(m => m.columnField === "status")?.value;


    initFilterModel.policyNumber = isValid(initFilterModel.policyNumber) ? initFilterModel.policyNumber : PloicyNum.current;
    initFilterModel.stateId = isValid(initFilterModel.stateId) ? initFilterModel.stateId : stateData.current;
    initFilterModel.insuredName = isValid(initFilterModel.insuredName) ? initFilterModel.insuredName : insName.current;

    apiClient(`/PolicyDashBoard/${action}`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(initFilterModel)
      }
    )
      .then((responseJson) => {
        if (!responseJson) return

        setloading(false);
        if (responseJson.data != null) {
          if (responseJson.data.totalCount > 0) {
            setNewComponentData(responseJson.data);
            // if (firstDataCount === 0) {
            //   firstDataCount = responseJson.data.totalCount;
            // }
          } else {
            setNewComponentData(initValue);
          }
        } else {
          setNewComponentData(initValue);
          setcomponentData([]);
        }
        setValue(newValue)
      })
      .finally(() => {
        setValue(newValue)
        setloading(false);
      });
  };



  interface dataExportParams {
    customerPolicyIds: number[],
    option: string
  }
  const initValueExport: dataExportParams = {
    customerPolicyIds: [],
    option: ""
  }
  const getCustomerPolicyListbyTaxType = (action: string, customerPolicyIds: number[], option: string) => {
    setloading(true);
    if (customerPolicyIds.length === 0) {
      customerPolicyIds = getCustomerPolicyIds
    }

    if(customerPolicyIds.length == 0) {
      customerPolicyIds = newComponentDatawithCount.getCompletedFilings_Respdata?.map((m: any) => m.customerpolicyid) || [];
    }

    initValueExport.customerPolicyIds = customerPolicyIds;
    initValueExport.option = option;
    console.time('===getCustomerPolicyListbyTaxType===')
    apiClient(`/PolicyDashBoard/${action}`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(initValueExport)
      }
    )
      .then((res) => {
        if (!res) return
        console.timeEnd('===getCustomerPolicyListbyTaxType===')
        if (res.data !== null) {
          let totalData: NewCompletedFilingsModel[] = [];
          if(res.data.outstandingFilingsData !== null){
            totalData = [...totalData, ...res.data.outstandingFilingsData]
          }
          if(res.data.completedFilingsData !== null){
            totalData = [...totalData, ...res.data.completedFilingsData]
          }
          if(res.data.closedFilingsData !== null){
            totalData = [...totalData, ...res.data.closedFilingsData]
          }
          if (totalData.length !== 0) handleExport(totalData, option, "ExportFilingsData").then(() => {
            //window.location.reload();
          });
          // if (res.data.outstandingFilingsData !== null) handleExport(res.data.outstandingFilingsData, option, "OutstandingFilingsData").then(() => {
          //   //window.location.reload();
          // });

          // if (res.data.completedFilingsData !== null) handleExport(res.data.completedFilingsData, option, "CompletedFilingsData").then(() => {
          //   // window.location.reload();
          // });

          // if (res.data.closedFilingsData !== null) handleExport(res.data.closedFilingsData, option, "ClosedFilingsData").then(() => {
          //   //  window.location.reload();
          // });

          if (res.data.selectedFilingsData !== null) handleExport(res.data.selectedFilingsData, option, "CustomerPolicyData").then(() => {
            // window.location.reload();
          });
        }
        setSelectedRows([]);
      })
      .finally(() => {
        setloading(false);
      });
  };

  function convertDateFormat(dateStr: string): string | undefined {
    if (!dateStr || isNaN(Date.parse(dateStr))) {
      return undefined;
    }

    return moment.utc(dateStr).format("MM/DD/YYYY");
  }
  

  const handleExport = async (items: NewCompletedFilingsModel[], option: string, fileName: string) => {
    console.log('option: ', option, 'fileName: ', fileName)
    console.time('===handleExport===')
    if (items.length === 0) {
      return
    }
    var filterExcelItem = option === "Short" ? excelItemsShort : option === "Detailed" ? excelItemsDetailed : option === "All" ? excelItemsAll : excelItemsShort;
    var filterColumnList = option === "Short" ? columnListShort : option === "Detailed" ? columnListDetailed : option === "All" ? columnListShort : columnListShort;
    fileName = option + '_' + fileName;
    const updates1: { [x: string]: any[] } = {}
    filterExcelItem.forEach(ex => {
      const headerRow = ex.columnOrder.map(idx => filterColumnList[idx].xlsHeader[0])
      updates1[fileName] = [headerRow]
    })

    items.forEach(item => {
      if (item.homeState === "NY" &&
        (item.transactionTypeName === "New Business" || item.transactionTypeName === "Renewal Business") &&
        item.filerNotes === "Filed on binder, need dec page") {
        item.filerNotes = "Need Dec Page";
      }
      const xlsHeaders: string[] = []
      Object.keys(item).map((field) => {
        if (!item[field as keyof NewCompletedFilingsModel]) return
        const column = filterColumnList.find(col => col.field === field)
        if (!column?.xlsHeader) return
        xlsHeaders.push(column.xlsHeader[0])
      })

      // date fromating
      item.effectiveDate = convertDateFormat(item.effectiveDate ?? '');
      item.dateFiled = convertDateFormat(item.dateFiled ?? '');
      item.dateInvoiceAdded = convertDateFormat(item.dateInvoiceAdded ?? '');
      item.invoiceDate = convertDateFormat(item.invoiceDate ?? '');
      item.dueDate = convertDateFormat(item.dueDate ?? '');
      item.policyExpDate = convertDateFormat(item.policyExpDate ?? '');
      item.paymentNoticeDate = convertDateFormat(item.paymentNoticeDate ?? '');
      item.diligentSearchToGMAC = convertDateFormat(item.diligentSearchToGMAC ?? '');
      item.firstRequest = convertDateFormat(item.firstRequest ?? '');
      item.secondRequest = convertDateFormat(item.secondRequest ?? '');
      item.thirdRequest = convertDateFormat(item.thirdRequest ?? '');

      const excelItem = filterExcelItem[0]
      const update1 = excelItem.columnOrder.map(idx => {
        const column = filterColumnList[idx]
        const field = column.field as keyof NewCompletedFilingsModel
        // const value = item[field]
        let value = item[field];
        if (
          field === "grossPremium" ||
          field === "taxAmt" ||
          field === "nonAdmittedPremium" ||
          field === "taxableFees" ||
          field === "nonTaxableFees" ||
          field === "taxBalance"
      ) {
          value = typeof value === 'string' ? value.replace(/[^0-9.]/g, '').trim() : value;
      }
        if (value === undefined || value === null) return
        switch (column.inputType) {
          case 'string':
            return value.toString()
          case 'custom':
            return value ? moment.utc(value as string).startOf('day').toDate() : undefined;
          case 'number':
            return +value
          case 'bool':
            return !!value
          default:
            return value
        }
      })
      updates1[fileName].push(update1)
      //updates1[excelItem.name].push(update1)
    })

    console.log({ updates1 })
    console.timeEnd('===handleExport===')

    filterExcelItem.forEach(excelItem => {
      if (!updates1[fileName] || updates1[fileName].length <= 1) return
      // const timeStamp = moment().format('MM.DD.YYYY')
      writeXlsxFile(updates1[fileName], `${fileName}.xlsx`)
    })
  }
  const navigate = useNavigate();

  const initialUserdata = { partnersId: 0, customerName: "" };
  const [Customer, setCustomer] = useState([initialUserdata]);
  const fetchCustomer = () => {
    apiClient("/Partners/getPartners", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((responseJson) => {
        if (!responseJson) return
        // Do something with the response

        setCustomer(responseJson.data);
      })
  };


  const FMSelectCustomCustomer: React.FC<SelectProps> = ({
    variant,
    ...props
  }) => {
    const { user } = useAuth();
    const token = user?.token;
    const handleChange = (event: SelectChangeEvent) => {
      setloading(true);
      setCustomerId(Number(event.target.value));
      customerData.current = Number(event.target.value);
      setloading(false);
    };

    return (
      <div>
        <FormControl fullWidth>
          {/* <InputLabel id="test-select-label"></InputLabel> */}
          <Select
            onChange={handleChange}
            value={customerId.toString()}
            id="test-select-label"
            autoWidth
            size="small"
            label={null}
            className="border-round custom-menu-item"
            IconComponent={ExpandMoreIcon}
            displayEmpty
          >
            <MenuItem key={0} value={0} className="custom-menu-item">
              Client
            </MenuItem>
            {Customer?.map((item, key) => (
              <MenuItem
                key={key}
                className="custom-menu-item"
                value={item.partnersId}
              >
                {item.customerName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const initialStatedata = { stateId: 0, stateName: "" };
  const [States, setStates] = useState([initialStatedata]);

  const fetchStateData = () => {
    apiClient("/States/getStates", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((responseJson) => {
        if (!responseJson) return
        // Do something with the response

        setStates(responseJson.data);
      })
  };

  const FMSelectStateCustom: React.FC<SelectProps> = ({
    variant,
    ...props
  }) => {
    const { user } = useAuth();
    const token = user?.token;
    const handleChange = (event: SelectChangeEvent) => {
      setloading(true);
      setstateId(Number(event.target.value));
      stateData.current = Number(event.target.value);
      setloading(false);
    };
    return (
      //   <Select
      //   value={stateId.toString()}
      //   size="small"
      //   onChange={handleChange}
      //   fullWidth
      // >
      //   <MenuItem value="">None</MenuItem>
      //   <MenuItem value="option1">Option 1</MenuItem>
      //   <MenuItem value="option2">Option 2</MenuItem>
      //   <MenuItem value="option3">Option 3</MenuItem>
      // </Select>
      <FormControl fullWidth>
        <Select
          onChange={handleChange}
          value={stateId.toString()}
          size="small"
          id="test-select-label"
          // autoWidth
          label={null}
          className="border-round custom-menu-item"
          IconComponent={ExpandMoreIcon}
          displayEmpty
        >
          <MenuItem value={"0"} className="custom-menu-item">Home State</MenuItem>
          {States?.map((state, key) => (
            <MenuItem
              key={key}
              value={state.stateId}
              className="custom-menu-item"
            >
              {state.stateName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  //const [selectedButton, setSelectedButton] = useState<number>(0);
  const [selectedButton, setSelectedButton] = useState<number>(8);

  const handleChange = (event: React.SyntheticEvent | undefined, newValue: number) => {

    setloading(true);
    setValue(newValue);
    setSelectedButton(newValue);

    if (params.LastTabId != String(newValue)) {
      //console.log("location c");      
      window.location.href = "/" + newValue;
    } else {
      setloading(false);
    }
  };

  useEffect(() => {
    let oldTabValue =
      params.LastTabId === undefined ||
        params.LastTabId === "" ||
        params.LastTabId === null
        ? 8
        : Number(params.LastTabId);
    console.log("useeffect triggered and OldTabValue", oldTabValue);
    setValue(oldTabValue);
    handleChange(undefined, oldTabValue);

    setcomponentData([]);
    fetchStateData();
    fetchCustomer();
    if (oldTabValue === 6) {
      // fetchNewData('getFlagsPendingReadyReviewFilings', oldTabValue, _pageSize, _currentPage);
      fetchDataForFilter("getCompletedFilingsFilterData", FMTabs.ReadyToFiling, _pageSize, _currentPage, queryOptions);

      return;
    }
    if (oldTabValue === 8) {
      if (user?.userRoles.split(",").includes("Super Admin")
        || user?.userRoles.split(",").includes("MISC State Reporter")
        || user?.userRoles.split(",").includes("MISC Filer")) {
        // fetchNewData('getOutstandingFilingsData', oldTabValue, _pageSize, _currentPage);
        fetchDataForFilter("getOutstandingFilingsFilterData", FMTabs.OutStandingFiling, _pageSize, _currentPage, queryOptions);
      } else {
        fetchData("getFIleMuleFlagsQueDataClient", oldTabValue)
        // action = "getFileQueDataClient";
      }
    }

    if (oldTabValue === 5) {
      // fetchNewData('getClosedFilings', oldTabValue, _pageSize, _currentPage);
      fetchDataForFilter("getClosedFilingsFilterData", FMTabs.CompletedFiling, _pageSize, _currentPage, queryOptions);
      return;
    }


  }, []);

  function polnumberchange(e: React.ChangeEvent<HTMLInputElement>) {
    setpolNumber(e.target.value);
    PloicyNum.current = encodeURIComponent(e.target.value);
  }

  function insurednameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setinsuredName(e.target.value);
    insName.current = e.target.value;
  }
  function effectiveDateStartChnage(e: string) {
    seteffectiveDateStart(e);
    effectiveDateSt.current = e;
  }
  function effectiveDateEndChnage(e: string) {
    seteffectiveDateEnd(e);
    effectiveDateEd.current = e;
  }

  function RunSearch() {


    if (value === 6) {
      // fetchNewData('getFlagsPendingReadyReviewFilings', value, _pageSize, _currentPage);
      fetchDataForFilter("getCompletedFilingsFilterData", FMTabs.ReadyToFiling, _pageSize, _currentPage, queryOptions)
      return;
    }
    if (value === FMTabs.OutStandingFiling) {

      if (user?.userRoles.split(",").includes("Super Admin")
        || user?.userRoles.split(",").includes("MISC State Reporter")
        || user?.userRoles.split(",").includes("MISC Filer")) {
        //fetchNewData('getOutstandingFilingsData', value, _pageSize, _currentPage);
        fetchDataForFilter("getOutstandingFilingsFilterData", FMTabs.OutStandingFiling, _pageSize, _currentPage, queryOptions)
      } else {
        fetchData("getFIleMuleFlagsQueDataClient", value)

      }
    }

    if (value === 5) {
      // fetchNewData('getClosedFilings', value, _pageSize, _currentPage);
      fetchDataForFilter("getClosedFilingsFilterData", FMTabs.CompletedFiling, _pageSize, _currentPage, queryOptions);
      return;
    }


  }

  const closePolicy = (id: number, action: string, value: number, message: string, isClosed: boolean, isRestoreToRec: boolean = false) => {
    Swal.fire({
      title: message,
      // text: "Click proceed to confirm!",
      text: "This would revert the transaction to it's most recent status and tab",
      iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      showCancelButton: true,
      confirmButtonColor: '#02A4E3',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      customClass: {
        confirmButton: 'btn-radius',
        cancelButton: 'btn-radius',
        popup: 'card-radius'
      }
    })
      .then((result) => {
        if (result.isConfirmed) {
          apiClient(`/PolicyDashBoard/closeCustomerPolicy?custPolicyId=${id}&&isClosed=${isClosed}&&isRestoreToRec=${isRestoreToRec}`,
            {
              method: 'POST',

              headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              })
            },
            {
              message: `Restore a customer policy status.`,
            }
          )
            .then((res) => {
              if (!res) return
              //handleSubmit()
              //setSubmitting(true);
              // fetchNewData(action, value, _pageSize, _currentPage);

              fetchDataForFilter(action, FMTabs.CompletedFiling, _pageSize, _currentPage, queryOptions);
              Swal.fire({
                title: res.data,
                text: res.message,
                icon: res.message,
                confirmButtonText: "OK",
              });
            }).finally(() => {
              setloading(false);
            });
        }
      })

  };

  function ClearSearch() {
    setloading(true);
    seteffectiveDateStart("");
    seteffectiveDateEnd("");
    setpolNumber("");
    setinsuredName("");
    setCustomerId(0);
    setstateId(0);
    setloading(false);

    PloicyNum.current = "";

    insName.current = "";
    stateData.current = 0;
    customerData.current = 0;
    effectiveDateEd.current = "";
    effectiveDateSt.current = "";

    if (value === 6) {
      // fetchNewData('getFlagsPendingReadyReviewFilings', value, _pageSize, _currentPage);
      fetchDataForFilter("getCompletedFilingsFilterData", FMTabs.ReadyToFiling, _pageSize, _currentPage, queryOptions)
      return;
    }

    if (value === 8) {
      if (user?.userRoles.split(",").includes("Super Admin")
        || user?.userRoles.split(",").includes("MISC State Reporter")
        || user?.userRoles.split(",").includes("MISC Filer")) {
        //fetchNewData('getOutstandingFilingsData', value, _pageSize, _currentPage);
        console.log("queryOptions", queryOptions)
        fetchDataForFilter("getOutstandingFilingsFilterData", value, _pageSize, _currentPage, queryOptions);
      } else {
        fetchData("getFileQueDataClient", 8)

      }
    }

    if (value === 5) {
      // fetchNewData('getClosedFilings', value, _pageSize, _currentPage);
      fetchDataForFilter("getClosedFilingsFilterData", FMTabs.CompletedFiling, _pageSize, _currentPage, queryOptions);

      return;
    }

  }


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
        {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
      </div>
      <Box sx={{ width: "100%" }}>
        <Box>
          <div >
            {user?.userRoles.split(",").includes("Super Admin") ||
              user?.userRoles.split(",").includes("MISC State Reporter") ||
              user?.userRoles.split(",").includes("MISC Filer") ? (
              <div className="col-lg-12    ">
                <div
                  className=" d-flex  "
                  style={{ marginBottom: "28px" }} role="group"
                  aria-label="Basic example"
                >
                  <React.Fragment>

                    <div className="col-4  text-center ">
                      <button type="button"
                        className={`btn-tab btn ${selectedButton === 8 ? "btn-Selected" : "btn-primary1"
                          }`}
                        {...a11yProps(8)}
                        onClick={() => handleChange(undefined, 8)}
                      >
                        Outstanding
                      </button>
                    </div>
                    <div className="col-4  text-center">
                      <button
                        type="button"
                        className={`btn-tab btn ${selectedButton === 6 ? "btn-Selected" : "btn-primary"
                          }`}
                        {...a11yProps(6)}
                        onClick={() => handleChange(undefined, 6)}
                      >
                        Ready to File
                      </button>
                    </div>

                    <div className="col-4 text-center">
                      <button
                        type="button"
                        className={`btn-tab btn ${selectedButton === 5 ? "btn-Selected" : "btn-primary"
                          }`}
                        {...a11yProps(5)}
                        onClick={() => handleChange(undefined, 5)}
                      >
                        Completed
                      </button>
                    </div>


                  </React.Fragment>
                </div>
              </div>

            ) : (
              <div className="col-lg-12 col-sm-12 d-flex  justify-content-center">
                {" "}
                <span style={{ fontSize: "24px" }}>Pending Documents</span>{" "}
              </div>
            )}
          </div>
        </Box>

        <Box sx={{}}>
          <Grid
            container
            spacing={2}
            className="filter-grid"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xl={2} lg={4} sm={6} xs={12}>
              {user?.userRoles.split(",").includes("Super Admin")
                || user?.userRoles.split(",").includes("MISC State Reporter")
                || user?.userRoles.split(",").includes("MISC Filer")
                ? (
                  <FMSelectCustomCustomer name="customer" />
                ) : ""}
            </Grid>
            <Grid item xl={2} lg={4} sm={6} xs={12} >
              <FMSelectStateCustom name="state" />
            </Grid>
            <Grid item xl={2} lg={4} sm={6} xs={12} className=" select-input-border">
              <TextField
                name="Insured Name"
                size="small"
                className="select-input-border text-input-box"
                autoFocus
                value={insuredName}
                label="Insured Name" onChange={insurednameChange}
                type="text"
                fullWidth
                variant="outlined"
                id="insuredName"

              />
            </Grid>
            <Grid item xl={2} lg={4} sm={6} xs={12} className=" select-input-border">
              <TextField
                value={PolNumber}
                name="PolicyNumber"
                size="small"
                autoFocus
                className="select-input-border text-input-box"
                label="Policy Number"
                onChange={polnumberchange}
                type="text"
                fullWidth
                variant="outlined"
                id="PolicyNumber"

              />
            </Grid>

            <Grid item xl={0.7} sm={12} xs={12} >
              <FormControl fullWidth>
                <div>
                  <button
                    type="button"
                    style={{}}
                    onClick={() => {
                      RunSearch();
                    }}
                    className=" active-btn1-blue-tabs"
                  >
                    Search
                  </button></div>
              </FormControl>
            </Grid>

            <Grid item xl={0.7} sm={12} xs={12} alignItems="center">
              <FormControl fullWidth>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      ClearSearch();
                    }}
                    className=" btn-clear-refresh btn"
                  >
                    Clear
                  </button></div>
              </FormControl>
            </Grid>

            <Grid item xl={0.7} sm={12} xs={12} alignItems="center">
              <FormControl fullWidth>
                <div >
                  <button
                    type="button"
                    onClick={() => {
                      window.location.reload();
                    }}
                    className=" btn-clear-refresh btn"
                  >
                    Refresh
                  </button>
                </div>
              </FormControl>
            </Grid>

          </Grid>
        </Box>
        <Box overflow={"scroll"}>

          <TabPanel value={value} index={8}>
            {user?.userRoles.split(",").includes("Super Admin")
              || user?.userRoles.split(",").includes("MISC State Reporter")
              || user?.userRoles.split(",").includes("MISC Filer") ? (
              <OutstandingFilings
                NewCompletedFilingsCL={newComponentDatawithCount}
                fetchDataMain={fetchNewData}
                closePolicy={closePolicy}
                fetchDataSorting={fetchDataSorting}
                getCustomerPolicyListbyTaxType={getCustomerPolicyListbyTaxType}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                getCustomerPolicyDetails={getCustomerPolicyDetails}
                setCurrentPage={setCurrentPage}
                _currentPage={_currentPage}
                setPageSize={setPageSize}
                _pageSize={_pageSize}
                fetchDataForFilter={fetchDataForFilter}
                queryOptions={queryOptions}
                // setQueryOptions={setQueryOptions}
                debouncedOnFilterChange={debouncedOnFilterChange}
                sortModelRef={sortModelRef}

              />
            ) : (
              <FilingQueue
                filingData={componentData}
                refreshDataAfterUpload={() => fetchData}
                refreshDataMain={fetchData}
                showPayouts={() => undefined}
                sNo={undefined}
              />
            )}
          </TabPanel>

          <TabPanel value={value} index={5}>
            <CompletedFilings NewCompletedFilingsCL={newComponentDatawithCount}
              fetchDataMain={fetchNewData}
              closePolicy={closePolicy}
              fetchCompletedDataSorting={fetchDataSorting}
              getCustomerPolicyListbyTaxType={getCustomerPolicyListbyTaxType}
              setCurrentPage={setCurrentPage}
              _currentPage={_currentPage}
              setPageSize={setPageSize}
              _pageSize={_pageSize}
              debouncedOnFilterChange={debouncedOnFilterChange}
              fetchDataForFilter={fetchDataForFilter}
              queryOptions={queryOptions}
              sortModelRef={sortModelRef}
            />
          </TabPanel>
          <TabPanel value={value} index={6}>
            {
              user?.userRoles.split(",").includes("Super Admin")
                || user?.userRoles.split(",").includes("MISC State Reporter")
                || user?.userRoles.split(",").includes("MISC Filer") ? (
                <NewCompletedFilings
                  NewCompletedFilingsCL={newComponentDatawithCount}
                  fetchDataMain={fetchNewData}
                  closePolicy={closePolicy}
                  fetchPendingDataSorting={fetchDataSorting}
                  getCustomerPolicyListbyTaxType={getCustomerPolicyListbyTaxType}
                  getCustomerPolicyDetails={getCustomerPolicyDetails}
                  setCurrentPage={setCurrentPage}
                  _currentPage={_currentPage}
                  setPageSize={setPageSize}
                  _pageSize={_pageSize}
                  queryOptions={queryOptions}
                  debouncedOnFilterChange={debouncedOnFilterChange}
                  fetchDataForFilter={fetchDataForFilter}
                  sortModelRef={sortModelRef}
                />
              ) : (
                ""
              )}
          </TabPanel>
          {/* <TabPanel value={value} index={6}></TabPanel> */}
          <TabPanel value={value} index={7}>
            coming Soon
          </TabPanel>
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
